<template>
   <v-row justify="center" class="flex-md-row-reverse">
      <v-col cols="12" class="text-h2">Teras</v-col>
      <v-col cols="12" sm="6" xl="4">
         <div class="text-center">
            <v-icon>mdi-thermometer</v-icon>
            <span class="text-h6">Outdoor Temperature</span>
            <div class="text-h2">{{ teras_temp }}°C</div>
         </div>
      </v-col>
      <v-col cols="12" sm="6" xl="4">
         <v-card class="mb-3">
            <v-card-title class="headline">Lampu</v-card-title>
            <v-card-text>
               <v-row>
                  <v-col>
                     <v-btn block rounded :outlined="lamp!==0" color="primary" @click="set('lamp',0)">OFF</v-btn>
                  </v-col>
                  <v-col>
                     <v-btn block rounded :outlined="lamp!==1" color="primary" @click="set('lamp',1)">ON</v-btn>
                  </v-col>
                  <v-col>
                     <v-btn block rounded :outlined="lamp!==2" color="primary" @click="set('lamp',2)">AUTO</v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field label="ON/Off Voltage" :value="teras_on_voltage" @input="set('teras_on_voltage', arguments[0])" />
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-switch label="Grow LED" @input="set('grow_led', arguments[0])" :value="grow_led==1" />
                  </v-col>
               </v-row>
            </v-card-text>
         </v-card>
         <v-card class="mb-3">
            <v-card-title class="headline">Pompa 1</v-card-title>
            <v-card-text>
               <v-row>
                  <v-col>
                     <v-btn block rounded :outlined="pump1!==0" color="primary" @click="set('pump1',0)">OFF</v-btn>
                  </v-col>
                  <v-col>
                     <v-btn block rounded :outlined="pump1!==1" color="primary" @click="set('pump1',1)">ON</v-btn>
                  </v-col>
                  <v-col>
                     <v-btn block rounded :outlined="pump1!==2" color="primary" @click="set('pump1',2)">AUTO</v-btn>
                  </v-col>
                  <v-col cols="5">
                     <v-text-field label="ON Time" :value="pump1_on_time" @input="set('pump1_on_time', arguments[0])" />
                  </v-col>
                  <v-col cols="5">
                     <v-text-field label="Interval" :value="pump1_interval" @input="set('pump1_interval', arguments[0])" />
                  </v-col>
               </v-row>
            </v-card-text>
         </v-card>
         <v-card class="mb-3">
            <v-card-title class="headline">Pompa 2</v-card-title>
            <v-card-text>
               <v-row>
                  <v-col>
                     <v-btn block rounded :outlined="pump2!==0" color="primary" @click="set('pump2',0)">OFF</v-btn>
                  </v-col>
                  <v-col>
                     <v-btn block rounded :outlined="pump2!==1" color="primary" @click="set('pump2',1)">ON</v-btn>
                  </v-col>
                  <v-col>
                     <v-btn block rounded :outlined="pump2!==2" color="primary" @click="set('pump2',2)">AUTO</v-btn>
                  </v-col>
                  <v-col cols="5">
                     <v-text-field label="ON Time" :value="pump2_on_time" @input="set('pump2_on_time', arguments[0])" />
                  </v-col>
                  <v-col cols="5">
                     <v-text-field label="Interval" :value="pump2_interval" @input="set('pump2_interval', arguments[0])" />
                  </v-col>
               </v-row>
            </v-card-text>
         </v-card>
      </v-col>
   </v-row>
</template>
<script>
import { mapState } from 'vuex';
export default {
   data: () => ({
   }),
   computed: {
      ...mapState('teras', ['lamp','grow_led','teras_on_voltage','pump1','pump1_interval', 'pump1_on_time','pump2','pump2_interval', 'pump2_on_time']),
      ...mapState(['teras_temp']),
   },
   methods: {
      set(dev, val) {
         this.$store.dispatch('teras/set', {[dev]: val});
      }
   }
}
</script>